import revive_payload_client_mhmX1bPkdw from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1._bn2tlfmp6h6si2m7zj4qvujw6a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_cZuksV7bFC from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1._bn2tlfmp6h6si2m7zj4qvujw6a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_IrmHIZr6f1 from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1._bn2tlfmp6h6si2m7zj4qvujw6a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_FOzRwMXBAQ from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1._bn2tlfmp6h6si2m7zj4qvujw6a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_dOZXJ2xVQj from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1._bn2tlfmp6h6si2m7zj4qvujw6a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_FjK2fDeI7M from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1._bn2tlfmp6h6si2m7zj4qvujw6a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_WNsQoNA4R7 from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1._bn2tlfmp6h6si2m7zj4qvujw6a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_GpQp8CEkxP from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1._bn2tlfmp6h6si2m7zj4qvujw6a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_0EJLZIymrn from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_D6eqCVnJhd from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.4_rollup@4.18.0_vue@3.5.11_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_OgJhDG7jb3 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.4_rollup@4.18.0_vue@3.5.11_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
export default [
  revive_payload_client_mhmX1bPkdw,
  unhead_cZuksV7bFC,
  router_IrmHIZr6f1,
  payload_client_FOzRwMXBAQ,
  navigation_repaint_client_dOZXJ2xVQj,
  check_outdated_build_client_FjK2fDeI7M,
  chunk_reload_client_WNsQoNA4R7,
  components_plugin_KR1HBZs4kY,
  prefetch_client_GpQp8CEkxP,
  plugin_client_0EJLZIymrn,
  switch_locale_path_ssr_D6eqCVnJhd,
  i18n_OgJhDG7jb3
]